@use "../config/" as *;

.waf-standings {
    .filter-section,.head-tab,.dropdown-wrap,.full-name,.form-guide,.waf-footer,.btn-text,.tied,.nr {
        @extend %d-none;
    }
    .waf-head {
        @extend %flex-sb-c;
        .head-wrap {
            @extend %gap-6;
        }
    }
    .table {
        @extend %pure-white-900-bg;
        &-row {
            @extend %relative;
            border-bottom: .1rem solid var(--neutral-200);
            &-head {
                border-bottom: 0;
            }
            &.active {
                flex-direction: column;
                .waf-accordion-head {
                    border-bottom: .1rem solid var(--neutral-200);
                }
                .btn-accordion-trigger {
                    &::after {
                        content: '\e806';
                    }
                }
            }
        }
    }
    .table-head {
        .table-row {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            background: linear-gradient(91.83deg, var(--secondary-800) 0%, var(--primary-700) 100%);
            border-bottom: 0;
            @extend %pr-6;
        }
        .table-data {
            height: 4rem;
            &.form-guide {
                @extend %font-12-pb;
                @extend %pure-white-900;
            }
        }
        .text {
            font-size: 1.2rem;
        }
    }
    .waf-accordion-head {
        @extend %w-100;
        .table-row {
            @extend %pr-6;
        }
        .table-data {
            p {
                @extend %font-12-pm;
            }
            &.points,&.matches-team,&.matches-position {
                p {
                    font-weight: 700;
                }
            }
        }
    }
    .matches-team {
        width: 25%;
        flex-shrink: 0;
        justify-content: flex-start;
        .name {
            @extend %neutral-800;
            @extend %uppercase;
        }
        .team-image {
            width: 3.4rem;
            height: 3.4rem;
            flex-shrink: 0;
            padding: .2rem;
            @extend %neutral-200-bg;
            @extend %circle-radius;
        }
        .table-data-wrap {
            @extend %gap-2;
            @extend %flex-n-c;
        }
    }
    .btn-accordion-trigger {
        position: absolute;
        right: var(--space-2);
        &::after {
            @include icon(chevron-down,12);
        }
    }
    .waf-accordion-panel {
        @extend %w-100;
        @include background(null, "pattern/mobile/standing-bg.png", top / cover no-repeat);
    }
    .match-listing {
        .match-list-wrap {
            overflow-x: auto;
            @extend %gap-3;
            @extend %px-3;
            @extend %py-6;
            @extend %flex;
        }
        .match-item {
            flex-shrink: 0;
            @extend %w-90;
            &-head {
                @extend %gap-4;
                @extend %pb-2;
                @extend %flex-c-c;
            }
            &-body {
                @extend %relative;
                @extend %py-3;
                @extend %px-4;
                @extend %half-radius;
                @extend %pure-white-900-bg;
            }
            &-footer {
                @extend %d-none;
            }
        }
        .btn-wrap {
            cursor: pointer;
            @extend %h-100;
            @extend %w-100;
            @extend %pos-bl;
            .btn-more {
                @extend %h-100;
                @extend %w-100;
                @extend %flex;
            }
        }
        .match-details {
            flex-wrap: wrap;
            @extend %flex-sb-c;
        }
        .match-meta  {
            @extend %capitalize;
            @extend %font-14-pm;
            @extend %pure-white-900;
        }
        .match-date {
            @extend %relative;
            &:before {
                content: '';
                width: .1rem;
                left: var(--space-2-neg);
                @extend %pos-y-center;
                @extend %h-100;
                @extend %pure-white-900-bg;
            }
        }
        .team {
            @extend %gap-2;
            @extend %flex-n-c;
            &.team-b {
                flex-direction: row-reverse;
                .team-score-wrap {
                    @extend %text-right;
                }
            }
            &.team-won {
                .score,.overs {
                    @extend %neutral-800;
                }
            }
        }
        .match-time {
            .time-text {
                width: 2rem;
                height: 2rem;
                @extend %flex-c-c;
                @extend %secondary-50-bg;
                @extend %primary-700;
                @extend %uppercase;
                @extend %font-12-pm;
                @extend %circle-radius;
            }
        }
        .team-image {
            width: 3.5rem;
            height: 3.5rem;
            padding: .3rem;
            flex-shrink: 0;
            @extend %neutral-200-bg;
            @extend %circle-radius;
        }
        .score {
            @extend %neutral-400;
            @extend %font-18-pb;
        }
        .overs {
            @extend %neutral-400;
            @extend %font-12-pr;
        }
        .run-rate,.match-number,.team-name {
            @extend %d-none;
        }
        .form-guide {
            @extend %d-none;
        }
    }
    .highlight {
        .waf-accordion-head {
            background: linear-gradient(90deg, var(--tertiary-600) 0%, clr(tertiary-600,0) 100%);
            @extend %relative;
            &:before {
                content: '';
                width: .4rem;
                left: 0;
                @extend %pos-y-center;
                @extend %h-100;
                @extend %accent-900-bg;
            }
        }
        &.active {
            @extend %tertiary-600-bg;
            .waf-accordion-head {
                border-bottom: 0;
            }
        }
        .position-indicator {
            @extend %pure-white-900-bg;   
        }
    }
    .form-guide-listing {
        @extend %flex-c-n;
        @extend %gap-2;
        .form-guide-item {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: .3rem;
            @extend %flex-c-c;
            .text {
                @extend %pure-white-900;
                @extend %uppercase;
                @extend %font-12-pm;
            }
            &.win {
                @extend %success-900-bg;
            }
            &.lost {
                @extend %accent-900-bg;
            }
            &.draw {
                @extend %neutral-800-bg;
            }
        }
    }
    .position-indicator {
        width: 2rem;
        height: 2rem;
        @extend %d-none;
        @extend %uppercase;
        @extend %neutral-800;
        @extend %font-16-pb;
        @extend %tertiary-600-bg;
        @extend %circle-radius;
    }
    .qualifier {
        .position {
            @extend %d-none;
            &-indicator {
                @extend %flex-c-c;
            }
        }
    }
}
@media screen and (max-width: 350px) {
    .waf-standings {
        .match-listing {
            .match-list-wrap {
                padding-inline: var(--space-2);
            }
            .match-item {
                width: 100%;
            }
        }
        .matches-nr {
            display: none;
        }
    }
}
@include mq(col-md) {
    .waf-standings {
        .tied,.nr {
            display: flex;
        }
        .match-listing {
            .match-item {
                width: calc(50% - .8rem);
            }
        }
        .form-guide {
            display: flex;
            flex-shrink: 0;
            width: 25%;
        }
        .table-head {
            .text,p {
                font-weight: 700;
                font-size: 1.4rem;
            }
        }
        .waf-accordion-head {
            .table-data p {
                font-size: 1.6rem;
            }
        }
        .standings-table {
            margin-top: var(--space-2);
            margin-bottom: var(--space-14);
        }
    }
}
@include mq(col-lg) {
    .waf-standings {
        .short-name {
            display: none;
        }
        .full-name {
            display: block;
        }
        .team-name {
            text-align: left;
        }
        .waf-accordion-panel {
            @include background(null, "pattern/standing-bg.png", top / cover no-repeat);
        }
        .table-data {
            height: 7.8rem;
        }
        .match-listing {
            .match-list-wrap {
                justify-content: center;
                padding-inline: 10rem;
            }
            .match-item {
                width: calc(33.33% - var(--space-2));
            }
        }
        .matches-position {
            flex-shrink: 0;
            width: 7%;
        }
        .matches-team {
            width: 35%;
            .team-image {
                width: 5.5rem;
                height: 5.5rem;
                padding: var(--space-1);
            }
            .name {
                text-transform: capitalize;
            }
        }
        .form-guide {
            width: 12%;
        }
        .table-head {
            .table-row {
                padding-right: var(--space-10); 
            }
        }
        .btn-accordion-trigger {
            right: var(--space-5);
        }
        .waf-accordion-head {
            .table-row {
                padding-right: var(--space-10);
            }
        }
        .position-indicator {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}